// based on https://brandhub.griffith.edu.au/resources/writing/guides/brand-identity-guide-web/Brand-and-Identity-Guide-2020.pdf
// pages 20 and 45

// Light Grey rgb 227, 9, 24
// Pale Grey rgb 227, 9, 24
// Griffith Red rgb 227, 9, 24

$theme-colors: (
  "beige": #c8a58a,
  "primary": #533f3f,
  // Feature Red
  "secondary": #E7E1DA,
  // Secondary Grey
  "success": rgba(0, 139, 0, 1),
  // Green
  "info": rgba(0, 71, 102, 0.07),
  // Primary Grey
  "warning": rgba(230, 81, 0, 1),
  // Orange
  "danger": rgb(165, 44, 43),
  // Griffith dark red
  "light": rgba(227, 233, 235, 1),
  // Tertiary White
  "dark": rgb(0, 0, 0),
  "transparent": transparent,
);

$dark-brown: #32241c;

// // https://www.npmjs.com/package/notosans-fontface
// $notosans-fontface-path: "../fonts/";
// @import "notosans-fontface/scss/notosans-fontface";

$font-family-sans-serif: "Foundry Sterling W01", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif: "Copernicus W01", Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$link-color: rgba(193, 37, 37, 1); //red
$link-hover-color: rgba(193, 37, 37, 1); //green

$pagination-active-color: map-get($theme-colors, "dark");
$pagination-active-bg: map-get($theme-colors, "secondary");
$pagination-active-border: map-get($theme-colors, "dark");

//Font size
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

//Headings font size
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 2.5rem,
  5: 2rem,
  6: 1.5rem,
);

@import "~bootstrap/scss/bootstrap";

h1 {
  font-family: "Jotia W00", $font-family-serif;
}
h2 {
  font-family: $font-family-serif;
} 
h3 {
  font-family: $font-family-serif;
}
h4 {
  font-family: $font-family-serif;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

$navbar-height: 81px;

.AppWrapper {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

//Add padding to offset the Navbar's height
#Navbar + div,
#Navbar + section {
  padding-top: $navbar-height;
}

//Fix Footer at the bottom
#Footer {
  margin-top: auto;
}

/* apply flexbox to <section> */
.col-page-section {
  display: flex;
  flex-direction: column;
  //height: calc(100vh - 135px);
  flex-wrap: wrap;
}

.col-page-section-container {
  display: flex;
  flex-flow: column;
  //height: 100vh;
  //background: #eeeeee;
}

/**
Custom button styles
*/
.btn-grid-icon {
  position: absolute !important;
  top: 5px;
  right: 5px;
}
.btn-fullscreen-icon {
  color: #333;
  border: none !important;

  &:hover,
  :active,
  :focus,
  .active {
    background: rgba(80, 80, 80, 0.2);
    color: #444;
    border: none !important;
  }
}

.btn {
  border-radius: 0;
}

.btn-arrow-up-right-square {
  color: #666 !important;
  border: none !important;

  &:hover,
  :active,
  :focus,
  .active {
    background: rgba(85, 85, 85, 0.1);
    //opacity: 0.7;
    color: #fff !important;
    border: none !important;
  }
}

.spinner {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
}

.featured-bio-card-text {
  border: none;
  position: relative;
  z-index: 2;
}

.featured-bio-card-body-size {
  min-height: 280px;
}

.featured-bio-card-title {
  font: italic 1.5em "Copernicus W01", Georgia, "Times New Roman", Times, serif !important;
}

.home-page-headings {
  font: 1.75em "Copernicus W01", Georgia, "Times New Roman", Times, serif !important;
  padding-bottom: 0.5em;
}

.home-page-main-sub-heading {
  font: 1.8em "Copernicus W01", Georgia, "Times New Roman", Times, serif !important;
}

.home-page-main-heading {
  font: 2.8em "Copernicus W01", Georgia, "Times New Roman", Times, serif !important;
}

.featured-bio-section {
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
    url(./assets/featured-bio.jpeg);
  height: auto;
}

.profile-pic {
  position: relative;
  z-index: 2;
  left: 40%;
  top: -2em;
  display: block;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-top: 0;
  background-color: #f7f7f7 !important;
}

.about {
  background-color: map-get($theme-colors, "secondary");
}

.how-to-use-dictionary {
  background-color: white;
}

.dictionary-details {
  background-color: map-get($theme-colors, "secondary");
}

.how-to-become-contributor {
  background-color:  #533f3f; //rgb(85,76,45);
}

.search-button-panel {
  background-color:  #523f3f; 
}

.footer {
  background-color:  #533f3f
}
.how-to-become-contributor a {
  color: white;
  text-decoration: underline;
  font-style: italic;
  font-weight: bold;
}

.pending-approval-account-message {
  text-align: center;
  position: relative;
  top: 50vh;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.text-dark-brown {
  color: $dark-brown;
}

@media only screen and (max-width: 991px) {
  .btn-place {
    margin-top: 0.5rem;
  }
}

@media only screen and (max-width: 390px) {
  .btn-place {
    margin-top: 0rem;
  }
}
